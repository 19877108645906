html {
  min-width: 100%;
}

* {
  user-select: none;
  -webkit-user-select: none;
}

body {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: url("./img/space6x.jpg");
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
}

pre {
  position: absolute;
  bottom: 1%;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  padding: 2px;
	background: rgba(255,255,255,0.5);
}

.hidden {
  display: none;
}
.inactive {
  pointer-events: none;
}

button {
  border-radius: 0.25em;
	background: rgb(18,170,23);
	border: 0;
	font-size: 2em;
	padding: 0.25em 0.75em;
	/* box-shadow: 0 0 15px 1px purple; */
	box-shadow: 0 0 15px 1px black;
  font-family: "Montserrat", sans-serif;
  text-shadow: 0 0 5px rgb(0,240,0);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}
button:focus {
  outline: none;
}
button:active {
  background: rgb(8,180,0);
  box-shadow: 0 0 7px 0px black;
}
button span {
  position: relative;
  display: inline-block;
}
button:active span {
  display: inline-block;
  top: 1px;
}

button.arrow {
  display: none;
  position: absolute;
  z-index: 999;
  width: 2em;
  height: 2em;
  text-align: center;
  top: 0.5em;
  font-family: "Press Start 2P", monospace;
  color: white;
  font-size: 2.5em;
  text-shadow: 0 0 10px black;
  background: transparent;
  border: 0;
  padding: 0;
  box-shadow: none;
  cursor: pointer;
  transition: text-shadow 200ms linear;
}
button.arrow.left {
  left: 0;
}
button.arrow.right {
  right: 0;
}
#playerCountSwipeWrap button.arrow {
  top: 1.75em;
}
#levelSwipeWrap button.arrow {
  top: 0;
}
button.arrow:hover {
  text-shadow: 0 0 25px gray;
}
body.notouch button.arrow {
  display: block;
}

#helpButton {
  position: absolute;
  z-index: 1001;
  top: 0.5em;
  right: 0.5em;
  border-radius: 1.25em;
  opacity: 0.0;
  pointer-events: none;
  text-shadow: none;
  transition:
    opacity          150ms ease-out,
    background-color 300ms ease-out;
}
#helpButton span {
  width: 0.25em;
  color: transparent;
  transform: translateX(-50%);
}
#helpButton.visible {
  opacity: 1.0;
  pointer-events: all;
}
#helpButton.active {
  background-color: gray;
}
#helpButton .q::before,
#helpButton .q::after {
  position: absolute;
  top: 0;
  color: white;
  text-shadow: 0 0 5px rgb(0,0,0);
  backface-visibility: hidden;
  transition: transform 300ms ease-out;
}
#helpButton .q {
  position: relative;
}
#helpButton .q::before {
  content: "?";
  left: 0;
  transform: rotateY(0deg);
}
#helpButton .q::after {
  content: "X";
  left: -3px;
  transform: rotateY(180deg);
}
#helpButton.active .q::before {
  transform: rotateY(180deg);
}
#helpButton.active .q::after {
  transform: rotateY(0deg);
}

#helpOverlay {
  opacity: 0.0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
  pointer-events: none;
  z-index: 999;
}
#helpOverlay.visible {
  display: block;
  pointer-events: all;
  opacity: 1.0;
}

#help {
  opacity: 0.0;
  position: absolute;
  z-index: 1000;
  top: 5%;
  width: 85%;
  max-width: 480px;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 1em;
  background: black;
  border: 8px solid rgb(18,170,23);
  line-height: 1.25em;
  padding: 1em;
  box-shadow: 0 0 15px 1px black;
  color: white;
  font-family: "Press Start 2P", monospace;
  font-size: 0.85em;
  pointer-events: none;
  overflow: scroll;
  user-select: none;
  -webkit-user-select: none;
  -webkit-overflow-scrolling: touch;
  transition: opacity 150ms ease-out;
}
body.notouch #help {
  overflow: auto;
}
@media only screen and (min-width: 480px) {
  #help {
    padding: 2em;
    font-size: 1em;
  }
}
#help.visible {
  display: block;
  pointer-events: all;
  opacity: 1.0;
}
#help h4 {
  margin-top: 0;
}
#help h4 span {
  padding-bottom: 0.5em;
  border-bottom: 0.25em solid white;
}
#help p {
  text-align: left;
}
#help p:first-child {
  margin-top: 0;
}
#help p:last-child {
  margin-bottom: 0;
}
#help em {
  font-style: normal;
  color: orange;
}
#help a {
  color: rgb(18,170,23);
}
#help a[href$=pdf] {
  color: rgb(184,61,133);
}
#help a.src {
  color: gray;
  margin: 0 auto;
  font-size: 0.75em;
}

#startButton,
#newGameButton {
  position: fixed;
  top: 65%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.App > div {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.row {
  padding-left:  1em;
  padding-right: 1em;
}
.row > div {
  margin: 0 auto;
  width: 100%;
  color: white;
  text-align: center;
  font-family: "Press Start 2P", monospace;
  text-shadow: 0 0 10px black;
}
.row.lives,
.row.stars {
  font-size: 1.5em;
}

.row.level {
  padding: 0;
}
.row.level > div {
  font-size: 2.5em;
  white-space: nowrap;
}
.reactSwipeWrap {
  position: absolute;
  left: 0;
  right: 0;
  font-size: 1em;
  color: white;
  text-align: center;
  font-family: "Press Start 2P", monospace;
  text-shadow: 0 0 10px black;
}
#playerCountSwipeWrap {
  top: 10%;
  bottom: 60%;
}
#levelSwipeWrap {
  top: 10%;
  bottom: 70%;
}
#livesSwipeWrap {
  top: 30%;
  bottom: 40%;
}
#starsSwipeWrap {
  top: 60%;
  bottom: 10%;
}
#extremeWrap {
  display: none;
}
.extreme #extremeWrap {
  display: block;
}
.extreme #levelSwipeWrap {
  top: 5%;
  bottom: 75%;
}
.extreme #livesSwipeWrap {
  top: 25%;
  bottom: 45%;
}
.extreme #starsSwipeWrap {
  top: 55%;
  bottom: 15%;
}
.extreme #extremeWrap {
  position: absolute;
  top: 90%;
  bottom: 0%;
}
#livesSwipeWrap .label,
#starsSwipeWrap .label {
  margin-top: -1.5em;
  padding-top: 0;
  padding-bottom: 0.5em;
  font-size: 1.5em;
  color: white;
  text-align: center;
  font-family: "Press Start 2P", monospace;
  text-shadow: 0 0 10px black;
}
.react-swipe-container > div > div {
  /* prevent temp image from overflowing out of bounds  */
  overflow: hidden;
}
.animateWrap .temp {
  position: absolute;
  top: 12px;
  transition: transform 400ms ease-out;
}
.no-transition .animateWrap .temp {
  transition-duration: 0ms !important;
}
.animateWrap .images > div {
  transition: transform 400ms ease-out;
}
.no-transition .animateWrap .images > div {
  transition-duration: 0ms !important;
}

#playerCountDisplay {
  display: none;
}
#playerCountDisplay.visible {
  display: block;
}

#isExtreme {
  position: fixed;
  bottom: 5%;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
}
#isExtreme label {
  color: orange;
  font-size: 1.25em;
  cursor: pointer;
}
#isExtreme .x {
  position: relative;
  font-size: 0.75em;
  padding-bottom: 0.1em;
  padding-left: 0.15em;
  padding-right: 0.05em;
  border: 3px solid orange;
  top: -0.25em;
}
#isExtreme .x.off {
  color: transparent;
}
#isExtreme input {
  display: none;
}

#extremeWrap > div {
  position: absolute;
  height: 1.75em;
  bottom: 0%;
  font-size: 1.25em;
  line-height: 24px;
}
#extremeWrap .hand {
  top: 0;
  position: absolute;
  left: 100%;
  width: 24px;
  height: 24px;
  padding: 0 12px;
  transition: transform 400ms ease-out;
  transform: translateY(200%);
}
#extremeWrap .hand.active {
  transform: translateY(0);
}
#extremeWrap .positive {
  left: 0.5em;
}
#extremeWrap .positive .hand {
  left: 100%;
}
#extremeWrap .negative {
  right: 0.5em;
  color: red;
}
#extremeWrap .negative .hand {
  left: -125%;
}

#levelSwipeSubtitle {
  line-height: 1.5em;
  white-space: nowrap;
}

@media only screen and (max-width: 699px) {
  #levelSwipeSubtitle {
    font-size: 0.7em;
  }
}

.images,
.label {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
#playerCountSwipeWrap .label {
  margin-top: 2em;
  font-size: 2em;
}

.row .images {
  white-space: nowrap;
  display: flex;
  /* margin-bottom: 0.5em; */
}
.row.stars,
.row.lives {
  padding-bottom: 2em;
}
.row .images > div {
  width: 1px;
  height: 90px;
  flex: 1 1 auto;
  position: relative;
}
img.star,
img.bunny {
  position: absolute;
  left: 50%;
  top: 0;
  width:  90px;
  height: 90px;
  transform: translateX(-50%);
  transition: filter 400ms ease-out;
}
img.faded {
  filter: grayscale(1) brightness(0.75);
}
.no-transition img.star {
  transition-duration: 0ms;
}

/* https://stackoverflow.com/questions/8693733/how-to-give-outer-glow-to-an-object-in-a-transparent-png-using-css3 */

#fireworks_wrap {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  pointer-events: none;
}

#fireworks {
}
